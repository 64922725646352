import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import { AdvancedFilters } from '../AdvancedFilters'
import { Button, Input, Spinner } from 'mmfintech-portal-commons'
import { CurrencyIcon, SelectAccount } from '../../../components'
import { CalendarIcon, ExchangeIcon, FiltersIcon, PayoutIcon, WithdrawIcon } from '../../../icons'
import {
  FiltersBalance,
  FiltersButtons,
  FiltersContainer,
  FiltersInitial,
  FiltersTopSection,
  FilterWrapper
} from './TransactionsFilter.styled'

import { tr, useWindowSize, formatMoney, GlobalContext, isValidArray } from 'mmfintech-commons'
import {
  paths,
  useGetActivePaymentAccountsQuery,
  useGetAggregatedBalanceQuery,
  usePermissions
} from 'mmfintech-backend-api'

import { AccountBalanceOut, PortalUserPermissionsEnum } from 'mmfintech-commons-types'

export const TransactionsFilter = ({ filter }) => {
  const { sidebarRightHide, sidebarRightShow } = useContext(GlobalContext)

  const { data: activePaymentAccounts } = useGetActivePaymentAccountsQuery()

  const history = useHistory()
  const [width] = useWindowSize()

  const { balance } = useGetAggregatedBalanceQuery(
    {
      dateFrom: '',
      dateTo: ''
    },
    {
      selectFromResult: ({ data }) => ({
        balance: isValidArray(data) ? data[data.length - 1] : null
      })
    }
  )

  const { hasAccessTo, hasWritePermission } = usePermissions()

  const handleWithdrawClick = () => {
    history.push(paths.withdraw.select())
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
  }

  const handlePayoutClick = () => {
    history.push(paths.payout.select())
  }

  const navigateToFilters = () => {
    sidebarRightShow({
      content: <AdvancedFilters stateFilter={filter} onClose={() => sidebarRightHide()} />,
      onClose: () => sidebarRightHide(),
      options: {
        closeOnClickOutside: true
      }
    })
  }

  return (
    <FilterWrapper>
      <FiltersContainer>
        <FiltersTopSection>
          <FiltersBalance>
            {balance ? (
              <>
                <div className='label'>
                  {tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}
                </div>
                <div className='balance'>
                  <CurrencyIcon currency={balance.currencyCode} size='30px' circle />
                  <div className='balance-text'>{formatMoney(balance.amount, balance.currencyCode)}</div>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </FiltersBalance>

          <FiltersButtons>
            {hasAccessTo(PortalUserPermissionsEnum.WITHDRAWALS) ? (
              <Button
                color='alternative'
                icon={<WithdrawIcon />}
                text={tr('FRONTEND.TRANSACTIONS.WITHDRAW_BUTTON', 'Withdraw')}
                onClick={handleWithdrawClick}
              />
            ) : null}
            {hasWritePermission() ? (
              <Button
                color='alternative'
                icon={<ExchangeIcon />}
                text={tr('FRONTEND.TRANSACTIONS.EXCHANGE_BUTTON', 'Exchange')}
                onClick={handleExchangeClick}
              />
            ) : null}
            {hasAccessTo(PortalUserPermissionsEnum.PAYOUTS) ? (
              <Button
                color='alternative'
                icon={<PayoutIcon />}
                text={tr('FRONTEND.TRANSACTIONS.PAYOUT_BUTTON', 'Payout')}
                onClick={handlePayoutClick}
              />
            ) : null}
          </FiltersButtons>

          <FiltersInitial>
            {activePaymentAccounts?.length > 0 && (
              <SelectAccount
                className='account-select'
                accounts={activePaymentAccounts}
                label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
                selectedAccount={activePaymentAccounts.find(
                  (account: AccountBalanceOut) => account.id === filter.values.get('accountId')
                )}
                setSelectedAccount={acc => {
                  filter.values.set('accountId', acc?.id)
                  filter.values.reload()
                }}
              />
            )}
            <Input
              type='date'
              name='from'
              maxDate={filter.values.get('to')}
              label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_DATE', 'From Date')}
              {...filter.values.registerInput('from')}
              data-test='filter-from-date'
              icon={<CalendarIcon />}
              hideErrorLine
            />
            <Input
              type='date'
              name='to'
              minDate={filter.values.get('from')}
              maxDate={moment()}
              label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_DATE', 'To Date')}
              {...filter.values.registerInput('to')}
              data-test='filter-to-date'
              icon={<CalendarIcon />}
              hideErrorLine
            />
            {width <= 1000 && (
              <span onClick={navigateToFilters}>
                <FiltersIcon />
              </span>
            )}
          </FiltersInitial>
        </FiltersTopSection>

        {width > 1000 && <AdvancedFilters stateFilter={filter} />}
      </FiltersContainer>
    </FilterWrapper>
  )
}
